<template>
  <!-- 数据看板>运营看板 -->
  <div class="dict">
    <div class="boardTitle">运营看板</div>
    <div class="container">
      <div class="container-first">
        <!-- 学生出险情况 -->
        <div class="echartsItem container-left" style="padding-top: 0.625vw">
          <div class="echartsType">
            <p class="echartsTitle">出险情况
              <el-tooltip placement="top">
                <div slot="content">
                  8月1日至今出险人数量
                </div>
                <span><img src="@/assets/img/wh.png" alt=""></span>
              </el-tooltip>
            </p>
            <el-radio-group v-model="insuranceBy" @change="changeInsuranceBy">
              <el-radio-button label="1">幼儿出险</el-radio-button>
              <el-radio-button label="2">员工出险</el-radio-button>
            </el-radio-group>
          </div>
          <div class="chartsContent" v-show="Object.keys(echartData1).length">
            <div id="insuranceByChart"></div>
          </div>
          <div class="noEachartData" v-if="!Object.keys(echartData1).length">
            暂无数据
          </div>
        </div>

        <!-- 督导任务 -->
        <div class="echartsItem container-middle">
          <div class="echartsType">
            <p class="echartsTitle">督导任务</p>
          </div>
          <div style="width: 100%; display: flex">
            <div style="width: 50%; height: 10.41667vw">
              <!-- <div class="chartsContent" v-show="Object.keys(echartData2).length"> -->
              <div
                id="superviseTypeChart"
                v-show="Object.keys(echartData2).length"
              ></div>
              <!-- </div> -->
              <div
                class="noEachartData"
                v-if="!Object.keys(echartData2).length"
              >
                暂无数据
              </div>
              <div class="echartsDesc">
                <div
                  class="itemDesc"
                  v-for="(item, index) in superviseType"
                  :key="index"
                >
                  <span :class="'dotColor bgColor' + index"></span>
                  <span class="optionTitle">{{ item.name }}</span>
                  <span class="peopleNum"
                    >{{
                      supSum !== 0
                        ? ((item.value / supSum) * 100).toFixed(2)
                        : 0
                    }}%</span
                  >
                </div>
              </div>
            </div>
            <div style="width: 50%; height: 10.41667vw">
              <!-- <div class="chartsContent" v-show="Object.keys(echartData3).length"> -->
              <div
                id="superviseAchievementChart"
                v-show="Object.keys(echartData3).length"
              ></div>
              <!-- </div> -->
              <div
                class="noEachartData"
                v-if="!Object.keys(echartData3).length"
              >
                暂无数据
              </div>
              <div class="echartsDesc">
                <div
                  class="itemDesc"
                  v-for="(item, index) in SuperviseAchievement"
                  :key="index"
                >
                  <span :class="'dotColor bgColor' + index"></span>
                  <span class="optionTitle">{{ item.name }}</span>
                  <span class="peopleNum"
                    >{{
                      supAchSum !== 0
                        ? ((item.value / supAchSum) * 100).toFixed(2)
                        : 0
                    }}%</span
                  >
                </div>
              </div>
            </div>

            <!-- :style="{ width: '50%', height: '16.66667vw' }" -->
          </div>
        </div>

        <!-- 满园率 -->
        <div class="echartsItem container-right" style="padding-top: 0.625vw">
          <div class="echartsType">
            <p class="echartsTitle">满园率
              <el-tooltip placement="top">
                <div slot="content">
                  学期累计（学期划分节点3.1、9.1)<br/>
                  满园率=达到满园目标的园校个数/所有园校个数<br/>
                  整体满园率=实际所有在校人数/所有满园目标和<br/>
                </div>
                <span><img src="@/assets/img/wh.png" alt=""></span>
              </el-tooltip>
            </p>
            <el-radio-group v-model="schoolPlan" @change="changeSchoolPlan">
              <el-radio-button label="1">本学期</el-radio-button>
              <el-radio-button label="2">上学期</el-radio-button>
            </el-radio-group>
          </div>
          <div class="chartsContent" v-show="Object.keys(echartData4).length">
            <div id="schoolPlanChart"></div>
          </div>
          <div class="noEachartData" v-if="!Object.keys(echartData4).length">
            暂无数据
          </div>
        </div>
      </div>

      <div class="container-second">
        <!-- 患病率/发病率 -->
        <div class="echartsItem container-left">
          <div class="echartsType">
            <p class="echartsTitle">保险在保率
              <!-- <el-tooltip placement="top">
                <div slot="content">
                  本学年累计（9月1日至今）<br/>
                  满园率=达到满园目标的园校个数/所有园校个数<br/>
                  总体满园率=实际所有在校人数/所有满园目标和
                </div>
                <span><img src="@/assets/img/wh.png" alt=""></span>
              </el-tooltip> -->
            </p>
          </div>
          <div class="chartsContent" v-show="Object.keys(echartData5).length">
            <div id="morChart"></div>
          </div>
          <!-- <div
            id="morChart"
            v-show="Object.keys(echartData5).length"
            :style="{ width: '100%', height: '18.75vw' }"
          ></div> -->
          <div class="noEachartData" v-if="!Object.keys(echartData5).length">
            暂无数据
          </div>
        </div>

        <!-- 保险在保率 -->
        <div class="echartsItem container-middle">
          <div style="display: flex">
            <div style="width: 50%">
              <div class="echartsType">
                <p class="echartsTitle">运营任务</p>
              </div>
              <!-- :style="{ width: '100%', height: '16.66667vw' }" -->
              <div
                id="operateInfoChart"
                v-show="Object.keys(echartData6).length"
                style="width: 100%; height: 10.41667vw"
              ></div>
              <div
                class="noEachartData"
                v-if="!Object.keys(echartData6).length"
              >
                暂无数据
              </div>
              <div class="echartsDesc">
                <div
                  class="itemDesc"
                  v-for="(item, index) in operateInfo"
                  :key="index"
                >
                  <span :class="'dotColor bgColor' + index"></span>
                  <span class="optionTitle">{{ item.name }}</span>
                  <span class="peopleNum"
                    >{{
                      operateInfoSum !== 0
                        ? ((item.value / operateInfoSum) * 100).toFixed(2)
                        : 0
                    }}%</span
                  >
                </div>
              </div>
            </div>
            <div style="width: 50%">
              <div class="echartsType">
                <p class="echartsTitle">园校拓展</p>
              </div>
              <!-- :style="{ width: '100%', height: '16.66667vw' }" -->
              <div
                id="schoolExpandChart"
                v-show="Object.keys(echartData7).length"
                style="width: 100%; height: 10.41667vw"
              ></div>
              <div
                class="noEachartData"
                v-if="!Object.keys(echartData7).length"
              >
                暂无数据
              </div>
              <div class="sign-con">
                <div
                  v-for="(item, index) in schoolExpandList"
                  :key="index"
                  class="sign-con-first"
                >
                  <span class="title">
                    {{ item.name }}
                  </span>
                  <span class="title">
                    {{ item.value }}
                  </span>
                </div>
                <!-- <div class="sign-con-first sign-con-second">
                  <span class="title">
                    {{ schoolExpandList[1].name }}
                  </span>
                  <span class="title">
                    {{ schoolExpandList[1].value }}
                  </span>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- 新园开办进度 -->
        <div class="echartsItem container-right">
          <div class="echartsType">
            <p class="echartsTitle">新园开办进度</p>
          </div>
          <div class="chartsContent" v-show="Object.keys(echartData8).length">
            <div id="runOffChart"></div>
          </div>
          <!-- <div
            id="runOffChart"
            v-show="Object.keys(echartData8).length"
            :style="{ width: '100%', height: '16.66667vw' }"
          ></div> -->
          <div class="noEachartData" v-if="!Object.keys(echartData8).length">
            暂无数据
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { extension } from "@/utils/validate";
import { mapGetters, mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      schoolPlan: 1,
      insuranceBy: 1,
      schoolPlanList: [],
      schoolExpandList: [],
      superviseType: [],
      supSum: "",
      SuperviseAchievement: [],
      supAchSum: "",
      operateInfo: [],
      operateInfoSum: "",
      echartData1: {
        name: [],
      },
      echartData2: {
        name: [],
      },
      echartData3: {
        name: [],
      },
      echartData4: {
        name: [],
      },
      echartData5: {
        name: [],
      },
      echartData6: {
        name: [],
      },
      echartData7: {
        name: [],
      },
      echartData8: {
        name: [],
      },
      insuranceByChart: null,
      superviseTypeChart: null,
      superviseAchievementChart: null,
      schoolPlanChart: null,
      morChart: null,
      operateInfoChart: null,
      schoolExpandChart: null,
      runOffChart: null,
    };
  },

  mounted() {
    this.getInsuranceByStudent();
    this.getSuperviseAchievementInfo();
    this.getSuperviseType();
    this.getSchoolPlanRate();
    this.getOperateInsure();
    this.getOperateAchievementInfo();
    this.getSchoolExpand();
    this.getNewSchoolPlan();

    window.addEventListener("resize", () => {
      this.insuranceByChart.resize();
      this.superviseTypeChart.resize();
      this.superviseAchievementChart.resize();
      this.schoolPlanChart.resize();
      this.morChart.resize();
      this.operateInfoChart.resize();
      this.schoolExpandChart.resize();
      this.runOffChart.resize();
    });
  },

  methods: {
    //图表数据

    //学生出险情况
    getInsuranceByStudent() {
      let params = {
        type: 1,
      };

      this.$api.getInsuranceByStudent(params).then((res) => {
        if (res.data.code == 0) {
          let insuranceDate = res.data.data;
          this.insuranceByChartInit(insuranceDate);
          // this.insuranceDateChartInit(insuranceDate);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //员工出险情况
    getInsuranceByStaff() {
      this.$api.getInsuranceByStaff().then((res) => {
        if (res.data.code == 0) {
          let insuranceDate = res.data.data;
          this.echartData1 = insuranceDate;
          this.insuranceByChartInit(insuranceDate);
          // this.insuranceDateChartInit(insuranceDate);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //督导任务完成情况
    getSuperviseAchievementInfo() {
      this.$api.getSuperviseAchievementInfo().then((res) => {
        if (res.data.code == 0) {
          this.SuperviseAchievement = res.data.data;
          let supNum1 = res.data.data[0].value;
          let supNum2 = res.data.data[1].value;
          let supNum3 = res.data.data[2].value;
          let supNum4 = res.data.data[3].value;
          this.supAchSum =
            parseInt(supNum1) +
            parseInt(supNum2) +
            parseInt(supNum3) +
            parseInt(supNum4);

          let superviseInfo = res.data.data;
          this.echartData3 = superviseInfo;
          this.superviseAchievementChartInit(superviseInfo);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // 督导标签类型
    getSuperviseType() {
      this.$api.getSuperviseType().then((res) => {
        if (res.data.code == 0) {
          this.superviseType = res.data.data;
          let supNum1 = 0;
          if (res.data.data.length > 0) {
            supNum1 = res.data.data[0].value;
          }
          let supNum2 = 0;
          if (res.data.data.length > 1) {
            supNum2 = res.data.data[1].value;
          }
          this.supSum = parseInt(supNum1) + parseInt(supNum2);
          let superviseType = res.data.data;
          this.echartData2 = superviseType;
          this.superviseTypeChartInit(superviseType);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //满园率
    getSchoolPlanRate() {
      this.$api.getSchoolPlanRate().then((res) => {
        if (res.data.code == 0) {
          this.schoolPlanList = res.data.data;
          let current = JSON.parse(
            JSON.stringify(this.schoolPlanList[0].current)
          );
          this.echartData4 = current;
          this.schoolPlanChartInit(current.current, current.totalRate);
          // this.changeSchoolPlan(schoolPlan);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //保险在保率
    getOperateInsure() {
      let params = {
        type: 1,
      };
      this.$api.getOperateInsure(params).then((res) => {
        if (res.data.code == 0) {
          let morDate = res.data.data;
          this.myChartInit(morDate);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //运营任务完成情况
    getOperateAchievementInfo() {
      this.$api.getOperateAchievementInfo().then((res) => {
        if (res.data.code == 0) {
          this.operateInfo = res.data.data;
          let supNum1 = res.data.data[0].value;
          let supNum2 = res.data.data[1].value;
          let supNum3 = res.data.data[2].value;
          let supNum4 = res.data.data[3].value;
          this.operateInfoSum =
            parseInt(supNum1) +
            parseInt(supNum2) +
            parseInt(supNum3) +
            parseInt(supNum4);
          let operateInfo = res.data.data;
          this.operateAchievementChartInit(operateInfo);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //园校拓展
    getSchoolExpand() {
      this.$api.getSchoolExpand().then((res) => {
        if (res.data.code == 0) {
          let schoolExpand = res.data.data;
          this.schoolExpandList = res.data.data;
          this.echartData7 = schoolExpand;
          this.schoolExpandChartInit(schoolExpand);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //新园开办
    getNewSchoolPlan() {
      this.$api.getNewSchoolPlan().then((res) => {
        if (res.data.code == 0) {
          let runOffDate = res.data.data;
          this.runOffChartInit(runOffDate);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    myChartInit(morDate) {
      this.echartData5 = morDate;
      let morChart = this.$echarts.init(document.getElementById("morChart"));

      let morChartOption = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          backgroundColor: "rgba(9, 12, 21, 0.8)",
          borderColor: "rgba(9, 12, 21, 0.8)",
          textStyle: {
            fontSize: 14,
            textAlign: "center",
            color: "#fff",
          },
          formatter: function (params) {
            var relVal = params[0].name;
            var names = ["学生", "员工"];
            for (var i = 0; i < params.length; i++) {
              relVal +=
                "<br/>" +
                params[i].marker +
                names[i] +
                " " +
                params[i].value +
                "%";
            }
            return relVal;
          },
        },
        title: {
          text: "保险在保率",
          left: 10,
          textStyle: {
            color: "#3D3E4F", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight: 400,
          },
          show: false,
        },
        legend: {
          data: ["学生", "员工"],
          show: true,
          itemWidth: 10,
          itemHeight: 10,
          right: 14,
          icon: "circle", //'image://../asset/ico/favicon.png',//标志图形类型，默认自动选择（8种类型循环使用，不显示标志图形可设为'none'），默认循环选择类型有：'circle' | 'rectangle' | 'triangle' | 'diamond' |'emptyCircle' | 'emptyRectangle' | 'emptyTriangle' | 'emptyDiamond'另外，还支持五种更特别的标志图形'heart'（心形）、'droplet'（水滴）、'pin'（标注）、'arrow'（箭头）和'star'（五角星），这并不出现在常规的8类图形中，但无论是在系列级还是数据级上你都可以指定使用，同时，'star' + n（n>=3)可变化出N角星，如指定为'star6'则可以显示6角星
          textStyle: {
            color: "#9290B1",
            fontSize: "14px",
          },
        },
        xAxis: {
          type: "category",
          data: ["在保", "未在保"],
          axisTick: {
            show: false, //不显示坐标轴刻度
          },
          axisLine: {
            show: false, //不显示坐标轴轴线
          },
          axisLabel: {
            rotate: 30,
            textStyle: {
              //改变xy轴上文字的颜色
              color: "#A8A6B9",
            },
          },
        },

        yAxis: {
          type: "value",
          minInterval: 1,
          axisLabel: {
            textStyle: {
              color: "#A8A6B9",
            },
          },
          // axisTick: {
          //   show: false  //不显示坐标轴刻度
          // },
          // axisLine: {
          //   show: false  //不显示坐标轴轴线
          // },
        },
        grid: {
          left: "14",
          right: "14",
          bottom: "10",
          containLabel: true,
        },
        series: [
          {
            name: "学生",
            type: "bar",
            barWidth: "20",
            data: morDate.insuranceRate[1],
            barGap: "30%" /*多个并排柱子设置柱子之间的间距*/,
            itemStyle: {
              //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius: [2, 2, 0, 0],
                color: "#004DA0",
              },
            },
          },
          {
            name: "员工",
            type: "bar",
            barWidth: "20",
            data: morDate.insuranceRate[0],
            barGap: "50%" /*多个并排柱子设置柱子之间的间距*/,
            itemStyle: {
              //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius: [2, 2, 0, 0],
                color: "#E7C69C",
              },
            },
          },
        ],
      };
      morChart.setOption(morChartOption);
      this.morChart = morChart;
    },

    insuranceDateChartInit(insuranceDate) {
      let insuranceChart = this.$echarts.init(
        document.getElementById("insuranceChart")
      );

      // var seriesData = [];
      //  for (var i = 0; i < insuranceDate["type"].length; i++) {
      // for (var i = 0; i < insuranceDate["insuranceNum"].length; i++) {
      //   var itemStyle = {};
      //   if (i == 0) {
      //     itemStyle = {
      //       normal: {
      //         //柱形图圆角，初始化效果
      //         barBorderRadius: [2, 2, 0, 0],
      //         color: "#00AB9F",
      //       },
      //     };
      //   } else if (i == 1) {
      //     itemStyle = {
      //       //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
      //       normal: {
      //         //柱形图圆角，初始化效果
      //         barBorderRadius: [2, 2, 0, 0],
      //         color: "#004DA0",
      //       },
      //     };
      //   } else if (i == 2) {
      //     itemStyle = {
      //       //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
      //       normal: {
      //         //柱形图圆角，初始化效果
      //         barBorderRadius: [2, 2, 0, 0],
      //         color: "#C5C3D8",
      //       },
      //     };
      //   }

      //   seriesData.push({
      //     name: insuranceDate.type[i],
      //     type: "bar",
      //     data: insuranceDate.insuranceNum[i],
      //     // barGap: "80%" /*多个并排柱子设置柱子之间的间距*/,
      //     // barCategoryGap: "50%" /*多个并排柱子设置柱子之间的间距*/,
      //     barWidth: 5, //柱图宽度
      //     itemStyle: itemStyle,
      //   });
      // }
      // }

      //出险情况
      let insuranceChartOption = {
        title: {
          text: "幼儿出险情况",
          left: 10,
          textStyle: {
            color: "#3D3E4F", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight: 400,
          },
        },
        legend: {
          data: insuranceDate.yAxis,
          show: true,
          itemWidth: 10,
          itemHeight: 10,
          right: 14,
          icon: "circle", //'image://../asset/ico/favicon.png',//标志图形类型，默认自动选择（8种类型循环使用，不显示标志图形可设为'none'），默认循环选择类型有：'circle' | 'rectangle' | 'triangle' | 'diamond' |'emptyCircle' | 'emptyRectangle' | 'emptyTriangle' | 'emptyDiamond'另外，还支持五种更特别的标志图形'heart'（心形）、'droplet'（水滴）、'pin'（标注）、'arrow'（箭头）和'star'（五角星），这并不出现在常规的8类图形中，但无论是在系列级还是数据级上你都可以指定使用，同时，'star' + n（n>=3)可变化出N角星，如指定为'star6'则可以显示6角星
          textStyle: {
            color: "#9290B1",
            fontSize: "14px",
          },
        },

        xAxis: {
          type: "category",
          data: insuranceDate.xAxis,
          axisTick: {
            show: false, //不显示坐标轴刻度
          },
          axisLine: {
            show: false, //不显示坐标轴轴线
          },
          axisLabel: {
            rotate: 30,
            textStyle: {
              //改变xy轴上文字的颜色
              color: "#A8A6B9",
            },
          },
        },

        yAxis: {
          type: "value",
          axisLabel: {
            textStyle: {
              color: "#A8A6B9",
            },
          },
          // axisTick: {
          //   show: false  //不显示坐标轴刻度
          // },
          // axisLine: {
          //   show: false  //不显示坐标轴轴线
          // },
        },
        grid: {
          left: "14",
          right: "14",
          bottom: "10",
          containLabel: true,
        },
        series: {
          name: insuranceDate.xAxis,
          type: "bar",
          barWidth: "10%",
          data: insuranceDate.yAxis,
        },
      };
      insuranceChart.setOption(insuranceChartOption);
    },

    //督导任务情况
    superviseAchievementChartInit(superviseInfo) {
      let superviseAchievementChart = this.$echarts.init(
        document.getElementById("superviseAchievementChart")
      );

      let superviseAchievementChartOption = {
        tooltip: {
          trigger: "item",
          show: false,
        },
        legend: {
          // orient: "horizontal",
          show: false,
          top: "70%",
          left: "center",
          // icon: "circle",
          // itemWidth: 10,
          // itemHeight: 10,
          // textStyle: {
          //   color: "#9290B1",
          //   lineHeight: 16,
          //   fontSize: 12,
          // },
        },
        color: ["#004DA0", "#E7C69C", "#00AB9F", "#93252A"],
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["45%", "60%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                formatter: function (params) {
                  let ele = `{a|${params.value}} \n {b| ${params.name}}`;
                  // let ele = `{a|${params.percent}} \n {b| ${params.name}}`;
                  return ele;
                },
                rich: {
                  a: {
                    fontSize: 20,
                    textAlign: "center",
                    color: "#000",
                  },
                  b: {
                    fontSize: 12,
                    textAlign: "center",
                    color: "#6C7293",
                    padding: [4, 0, 0, 0],
                  },
                },
              },
            },
            labelLine: {
              show: false,
            },
            data: superviseInfo,
          },
        ],
      };
      superviseAchievementChart.setOption(superviseAchievementChartOption);
      let dataCount = superviseInfo.length;
      // 设置默认展示，seriesIndex：系列名，dataIndex：当前索引
      superviseAchievementChart.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: 0,
      });
      // 鼠标进入高亮当前，取消其他高亮
      superviseAchievementChart.on("mouseover", (e) => {
        superviseAchievementChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: e.dataIndex,
        });
        // 根据当前option中data的length，遍历取消其他高亮
        for (let i = 0; i < dataCount; i++) {
          if (i != e.dataIndex) {
            superviseAchievementChart.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: i,
            });
          }
        }
      });
      //鼠标移出后显示之前默认高亮的那块，其他取消高亮
      superviseAchievementChart.on("mouseout", (e) => {
        if (e.dataIndex !== 0) {
          superviseAchievementChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: e.dataIndex,
          });
        } else {
          superviseAchievementChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: 0,
          });
        }
      });
      this.superviseAchievementChart = superviseAchievementChart;
    },

    //督导任务标签
    superviseTypeChartInit(superviseType) {
      let superviseTypeChart = this.$echarts.init(
        document.getElementById("superviseTypeChart")
      );

      let superviseTypeChartOption = {
        tooltip: {
          trigger: "item",
          show: false,
        },
        legend: {
          // orient: "horizontal",
          show: false,
          top: "70%",
          left: "center",
          // icon: "circle",
          // itemWidth: 10,
          // itemHeight: 10,
          // textStyle: {
          //   color: "#9290B1",
          //   lineHeight: 16,
          //   fontSize: 12,
          // },
        },
        color: ["#004DA0", "#E7C69C", "#00AB9F", "#93252A"],
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["45%", "60%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                formatter: function (params) {
                  // let ele = `{a|${params.percent}%} \n {b| ${params.name}}`;
                  let ele = `{a|${params.value}} \n {b| ${params.name}}`;
                  return ele;
                },
                rich: {
                  a: {
                    fontSize: 20,
                    textAlign: "center",
                    color: "#000",
                  },
                  b: {
                    fontSize: 12,
                    textAlign: "center",
                    color: "#6C7293",
                    padding: [4, 0, 0, 0],
                  },
                },
              },
            },
            labelLine: {
              show: false,
            },
            data: superviseType,
          },
        ],
      };
      superviseTypeChart.setOption(superviseTypeChartOption);
      //获取当前series中data长度，后面遍历取消高亮
      let dataCount = superviseType.length;
      // 设置默认展示，seriesIndex：系列名，dataIndex：当前索引
      superviseTypeChart.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: 0,
      });
      // 鼠标进入高亮当前，取消其他高亮
      superviseTypeChart.on("mouseover", (e) => {
        superviseTypeChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: e.dataIndex,
        });
        // 根据当前option中data的length，遍历取消其他高亮
        for (let i = 0; i < dataCount; i++) {
          if (i != e.dataIndex) {
            superviseTypeChart.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: i,
            });
          }
        }
      });
      //鼠标移出后显示之前默认高亮的那块，其他取消高亮
      superviseTypeChart.on("mouseout", (e) => {
        if (e.dataIndex !== 0) {
          superviseTypeChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: e.dataIndex,
          });
        } else {
          superviseTypeChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: 0,
          });
        }
      });
      this.superviseTypeChart = superviseTypeChart;
    },

    //运营任务完成情况
    operateAchievementChartInit(operateInfo) {
      this.echartData6 = operateInfo;
      let operateInfoChart = this.$echarts.init(
        document.getElementById("operateInfoChart")
      );

      let operateInfoChartOption = {
        tooltip: {
          trigger: "item",
          show: false,
        },
        legend: {
          orient: "horizontal",
          show: false,
          top: "70%",
          left: "center",
          // icon: "circle",
          // itemWidth: 10,
          // itemHeight: 10,
          // textStyle: {
          //   color: "#9290B1",
          //   lineHeight: 16,
          //   fontSize: 12,
          // },
        },
        color: ["#004DA0", "#E7C69C", "#00AB9F", "#93252A"],
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["45%", "60%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                formatter: function (params) {
                  let ele = `{a|${params.value}} \n {b| ${params.name}}`;
                  // let ele = `{a|${params.percent}%} \n {b| ${params.name}}`;
                  return ele;
                },
                rich: {
                  a: {
                    fontSize: 20,
                    textAlign: "center",
                    color: "#000",
                  },
                  b: {
                    fontSize: 12,
                    textAlign: "center",
                    color: "#6C7293",
                    padding: [4, 6, 0, 0],
                  },
                },
              },
            },
            labelLine: {
              show: false,
            },
            data: operateInfo,
          },
        ],
      };
      operateInfoChart.setOption(operateInfoChartOption);
      let dataCount = operateInfo.length;
      // 设置默认展示，seriesIndex：系列名，dataIndex：当前索引
      operateInfoChart.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: 0,
      });
      // 鼠标进入高亮当前，取消其他高亮
      operateInfoChart.on("mouseover", (e) => {
        operateInfoChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: e.dataIndex,
        });
        // 根据当前option中data的length，遍历取消其他高亮
        for (let i = 0; i < dataCount; i++) {
          if (i != e.dataIndex) {
            operateInfoChart.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: i,
            });
          }
        }
      });
      //鼠标移出后显示之前默认高亮的那块，其他取消高亮
      operateInfoChart.on("mouseout", (e) => {
        if (e.dataIndex !== 0) {
          operateInfoChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: e.dataIndex,
          });
        } else {
          operateInfoChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: 0,
          });
        }
      });
      this.operateInfoChart = operateInfoChart;
    },

    //园校拓展
    schoolExpandChartInit(schoolExpand) {
      let schoolExpandChart = this.$echarts.init(
        document.getElementById("schoolExpandChart")
      );
      let textZnum = "";
      let firstObj = schoolExpand[0];
      let secondObj = schoolExpand[1];

      if (secondObj.value == 0) {
        textZnum = 0 + "%";
      } else {
        textZnum =
          (
            (parseInt(firstObj.value) / parseInt(secondObj.value)) *
            100
          ).toFixed(0) + "%";
      }

      let schoolExpandChartOption = {
        // backgroundColor: "#050038",
        title: {
          text: textZnum,
          left: "center",
          top: "35%",
          textStyle: {
            fontWeight: "bold",
            fontSize: 25,
            color: "rgb(0,0,0)",
          },
          subtext: `已签约`, //副标题
          subtextStyle: {
            fontWeight: "normal",
            fontSize: 14,
            color: "rgb(0,0,0)",
          },
        },

        series: [
          {
            waveAnimation: false,
            nimationDuration: 0,
            animationDurationUpdate: 0,
            type: "liquidFill", // 图表类型
            radius: "65%",
            center: ["50%", "50%"],
            data: [firstObj.value / secondObj.value], // data个数代表波浪数
            backgroundStyle: {
              borderWidth: 1,
              color: "#F4F9FF",
            },
            label: {
              normal: {
                show: false, //不显示label  用副标题代替了
                formatter: (0.5 * 100).toFixed(0) + "%",
                textStyle: {
                  fontSize: 38,
                  color: "black",
                },
              },
            },
            color: [
              //color一定要是个数组 ，每一项对应一个波浪，可以给每个波浪单独配置颜色
              {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 1,
                    color: "rgba(130, 188, 255, 1)",
                  },
                  {
                    offset: 0,
                    color: "rgba(0, 77, 160, 1)",
                  },
                ],
                globalCoord: false,
              },
            ],
            outline: {
              show: false,
            },
          },
        ],
      };
      schoolExpandChart.setOption(schoolExpandChartOption);
      this.schoolExpandChart = schoolExpandChart;
    },

    changeSchoolPlan(val) {
      if (val == 1) {
        let current = JSON.parse(
          JSON.stringify(this.schoolPlanList[0].current)
        );
        this.echartData4 = current;
        this.schoolPlanChartInit(current.current, current.totalRate);
      } else {
        let last = JSON.parse(JSON.stringify(this.schoolPlanList[1].last));
        this.echartData4 = last;
        this.schoolPlanChartInit(last.current, last.totalRate);
      }
    },

    //满园率
    schoolPlanChartInit(schoolPlan, totalRate) {
      let schoolPlanChart = this.$echarts.init(
        document.getElementById("schoolPlanChart")
      );
      let colorArr = ["#004DA0", "#EDF2F7"];
      if (schoolPlan) {
        schoolPlan.forEach((e, i) => {
          e.itemStyle = {
            emphasis: {
              color: colorArr[i], //移入后的颜色
            },
          };
        });
      }
      let schoolPlanChartOption = {
        title: {
          text: totalRate + "%",
          left: "center",
          top: "33%",
          textStyle: {
            fontSize: 20,
            textAlign: "center",
            color: "#000",
          },
          subtext: `整体满园率`, //副标题
          subtextStyle: {
            fontSize: 12,
            textAlign: "center",
            color: "#6C7293",
            padding: [4, 6, 0, 0],
          },
        },

        tooltip: {
          //此处配置鼠标移动对应区域时的黑色弹框
          trigger: "item",
          show: true,
          // formatter: "{b}: {c} ({d}%)",
          formatter: "园校：{c}</br>占比：{d}%",
          backgroundColor: "rgba(9, 12, 21, 0.8)",
          borderColor: "rgba(9, 12, 21, 0.8)",
          textStyle: {
            fontSize: 14,
            textAlign: "center",
            color: "#fff",
          },
        },
        legend: {
          orient: "horizontal",
          show: true,
          top: "70%",
          icon: "circle",
          textStyle: {
            color: "#9290B1",
            lineHeight: 30,
            fontSize: 14,
          },
          selectedMode: false,
        },
        color: ["#004DA0", "#EDF2F7"],
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["45%", "60%"],
            center: ["50%", "40%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            // itemStyle:{
            //   normal: {
            //       color: '#EAF0F0',//移入前的颜色
            //   },
            //   emphasis:{
            //       color:"#EAF0F0",//移入后的颜色
            //   }
            // },
            emphasis: {
              label: {
                show: false,
                // formatter: function(params) {
                //   console.log(params, "这个是不是满园率");
                //   let ele = `{a|${params.percent}%} \n {b| ${params.name}}`;
                //   return ele;
                // },
                rich: {
                  a: {
                    fontSize: 20,
                    textAlign: "center",
                    color: "#000",
                  },
                  b: {
                    fontSize: 12,
                    textAlign: "center",
                    color: "#6C7293",
                    padding: [4, 6, 0, 0],
                  },
                },
              },
            },
            labelLine: {
              show: false,
            },
            data: schoolPlan,
          },
        ],
      };
      schoolPlanChart.setOption(schoolPlanChartOption);
      this.schoolPlanChart = schoolPlanChart;
      // let dataCountData = schoolPlan
      // let dataCount = schoolPlan.length;
      // // 设置默认展示，seriesIndex：系列名，dataIndex：当前索引
      // schoolPlanChart.dispatchAction({
      //   type: "highlight",
      //   seriesIndex: 0,
      //   dataIndex: 0,
      // });
      // // 鼠标进入高亮当前，取消其他高亮
      // schoolPlanChart.on("mouseover", (e) => {
      //   // console.log(e,'8989')
      //   schoolPlanChart.dispatchAction({
      //     type: "highlight",
      //     seriesIndex: 0,
      //     dataIndex: e.dataIndex,
      //   });
      //   // 根据当前option中data的length，遍历取消其他高亮
      //   for(let i =0;i<dataCount;i++){
      //     if(i != e.dataIndex){
      //       schoolPlanChart.dispatchAction({
      //       type: "downplay",
      //       seriesIndex: 0,
      //       dataIndex: i,
      //     });
      //     }
      //   }
      // });
      // //鼠标移出后显示之前默认高亮的那块，其他取消高亮
      // schoolPlanChart.on("mouseout", (e) => {
      //   // console.log(e,'你猜')
      //   if (e.dataIndex !== 0) {
      //     schoolPlanChart.dispatchAction({
      //       type: "highlight",
      //       seriesIndex: 0,
      //       dataIndex: e.dataIndex,
      //     });
      //   } else {
      //     schoolPlanChart.dispatchAction({
      //       type: "highlight",
      //       seriesIndex: 0,
      //       dataIndex: 0,
      //     });
      //   }
      // });
    },

    changeInsuranceBy(val) {
      if (val == 1) {
        this.getInsuranceByStudent();
      } else {
        this.getInsuranceByStaff();
      }
    },

    insuranceByChartInit(insuranceDate) {
      let insuranceByChart = this.$echarts.init(
        document.getElementById("insuranceByChart")
      );

      var insuranceByChartOption = {
        color: ["#004DA0"],
        // title: {
        //   text: "星期-出险数量对比图",
        //   left: 20,
        //   textStyle: {
        //     color: "#3D3E4F", // 主标题文字的颜色。
        //     fontSize: 18, // 字体大小
        //     fontWeight: 400,
        //   },
        // },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          backgroundColor: "rgba(9, 12, 21, 0.8)",
          borderColor: "rgba(9, 12, 21, 0.8)",
          textStyle: {
            fontSize: 14,
            textAlign: "center",
            color: "#fff",
          },
        },

        grid: {
          top: "35",
          left: "20",
          right: "20",
          bottom: "50",
          containLabel: true,
        },
        // xAxis: {
        //   type: "category",
        //   data: weekData.xList,
        // },
        xAxis: [
          {
            type: "category",
            data: insuranceDate.xAxis,
            triggerEvent: true,
            axisLabel: {
              color: "#A8A6B9",
              rotate: 30,
              margin: 8,
              formatter:function(params){
                var val="";
                if(params.length >4){
                    val = params.substr(0,4)+'...';
                    return val;
                }else{
                    return params;
                }
              },
            },
            /*改变x轴颜色*/
            axisLine: {
              lineStyle: {
                color: "#EEEFF5",
                width: 1, //这里是为了突出显示加上的
              },
            },
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            minInterval: 1,
            axisLabel: {
              color: "#A8A6B9",
              
            },
            // 分割线
            splitLine: {
              show: true,
              lineStyle: {
                color: "#EEEFF5",
                // type: "dashed", //虚线
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            name: "出险",
            type: "bar",
            barWidth: "20",
            data: insuranceDate.yAxis,
            itemStyle: {
              //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius: [4, 4, 0, 0],
              },
            },
          },
        ],
      };
      insuranceByChart.setOption(insuranceByChartOption);
      extension(insuranceByChart);
      this.insuranceByChart = insuranceByChart;
    },

    operateDateChartInit(operateDate) {
      let operateChart = this.$echarts.init(
        document.getElementById("operateChart")
      );

      let operateChartOption = {
        tooltip: {
          trigger: "item",
          show: false,
        },
        legend: {
          orient: "vertical",
          show: true,
          top: 80,
          left: "65%",
          icon: "circle",
          textStyle: {
            color: "#9290B1",
            lineHeight: 30,
            fontSize: 14,
          },
        },
        color: ["#004DA0", "#C5C3D8"],
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["45%", "60%"],
            center: ["35%", "40%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                formatter: function (params) {
                  let ele = `{a|${params.percent}%} \n {b| ${params.name}}`;
                  return ele;
                },
                rich: {
                  a: {
                    fontSize: 20,
                    textAlign: "center",
                    color: "#000",
                  },
                  b: {
                    fontSize: 12,
                    textAlign: "center",
                    color: "#6C7293",
                  },
                },
              },
            },
            labelLine: {
              show: false,
            },
            data: operateDate.insuranceRate,
          },
        ],
      };
      operateChart.setOption(operateChartOption);
    },

    runOffChartInit(runOffDate) {
      this.echartData8 = runOffDate.xAxis;
      let runOffChart = this.$echarts.init(
        document.getElementById("runOffChart")
      );
      // runOffDate.yAxis = ['11']
      let runOffChartOption = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          backgroundColor: "rgba(9, 12, 21, 0.8)",
          borderColor: "rgba(9, 12, 21, 0.8)",
          textStyle: {
            fontSize: 14,
            textAlign: "center",
            color: "#fff",
          },
          formatter: function (val) {
            return (
              val[0].name +
              '<span style="width:20px;display: inline-block;"></span>' +
              val[0].value +
              "%"
            );
          },
        }, //提示框，鼠标悬浮交互时的信息提示
        xAxis: {
          type: "value",
          axisTick: {
            show: false, //不显示坐标轴刻度
          },
          axisLine: {
            show: false, //不显示坐标轴轴线
          },
          axisLabel: {
            // rotate:30,
            textStyle: {
              //改变xy轴上文字的颜色
              color: "#A8A6B9",
            },
          },
          max: 100,
        },
        yAxis: {
          type: "category",
          data: runOffDate.xAxis,
          axisLabel: {
            textStyle: {
              color: "#A8A6B9",
            },
          },
          axisTick: {
            show: false, //不显示坐标轴刻度
          },
          axisLine: {
            show: false, //不显示坐标轴轴线
          },
        },
        grid: {
          top: "20",
          left: "10",
          right: "45",
          bottom: "10",
          containLabel: true,
        },
        series: [
          {
            // data: ['郑州中心园','郑州中心园','郑州中心园','郑州中心园','郑州中心园','郑州中心园','郑州中心园'],
            data: runOffDate.yAxis,
            type: "bar",
            showBackground: true,
            // bottom:10,
            barWidth: 20, //柱图宽度
            itemStyle: {
              //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius: [0, 4, 4, 0],
                color: function (p) {
                  if (p.value >= 100) {
                    return "#E7C69C";
                  } else {
                    return "#004DA0";
                  }
                }, //柱形背景颜色
                label: {
                  formatter: "{c}" + "%",
                  show: true,
                  position: "right",
                  textStyle: {
                    fontSize: "14",
                    color: "#9290B1",
                  },
                },
              },
            },
            backgroundStyle: {
              color: "#F3F3FB",
            },
          },
        ],
      };
      runOffChart.setOption(runOffChartOption);
      this.runOffChart = runOffChart;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.insuranceByChart.resize();
      this.superviseTypeChart.resize();
      this.superviseAchievementChart.resize();
      this.schoolPlanChart.resize();
      this.morChart.resize();
      this.operateInfoChart.resize();
      this.schoolExpandChart.resize();
      this.runOffChart.resize();
    });
  },
};
</script>

<style lang="scss" scoped>
.dict {
  width: calc(100% - 60px);
  height: calc(100% - 64px);
  background-color: #f4f4fc;
  // height: calc(100% - 40px);
  padding: 34px 30px 30px 30px;

  .boardTitle {
    color: #3d3e4f;
    font-size: 22px;
    font-weight: bold;
  }

  .echartsDesc {
    display: flex;
    width: 92%;
    justify-content: center;
    margin: 0 auto;
    flex-wrap: wrap;
    align-items: center;
    .itemDesc {
      width: 50%;
      margin-top: 10px;
      .dotColor {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 4px;
      }
      .bgColor0 {
        background: #004da0;
      }
      .bgColor1 {
        background: #e7c69c;
      }
      .bgColor2 {
        background: #00ab9f;
      }
      .bgColor3 {
        background: #93252a;
      }
      .benke {
        background: #004da0;
      }
      .dazhuan {
        background: #e7c69c;
      }
      .shuoshi {
        background: #00ab9f;
      }
      .zhongzhuan {
        background: #93252a;
      }
      .optionTitle {
        width: 6em;
        font-size: 12px;
        display: inline-block;
        color: #6c7293;
        // margin-right: 5px;
      }
      .peopleNum {
        color: #6c7293;
        font-size: 12px;
      }
    }
  }

  .tableList {
    height: calc(100% - 62px);
  }

  .container {
    width: 100%;
    height: calc(100% - 22px);
    .echartsItem {
      background-color: #fff;
      box-shadow: 0px 0px 3px rgba(37, 57, 177, 0.04);
      border-radius: 8px;
      height: 380px;
      margin-top: 30px;
      .echartsTitle {
        // padding: 28px 30px;
        font-size: 18px;
        color: #26262e;
      }
    }

    .chartsContent {
      width: calc(100% - 20px);
      height: calc(100% - 43px);
      padding: 10px;
      overflow: auto;
    }

    #insuranceByChart,
    #schoolPlanChart,
    #morChart,
    #operateInfoChart,
    #schoolExpandChart,
    #runOffChart {
      width: 100%;
      height: 100%;
    }

    #superviseTypeChart,
    #superviseAchievementChart {
      width: 100%;
      height: 100%;
    }

    .container-first,
    .container-second {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .container-first {
      // margin-top: 10px;
      .echartsItem {
        margin-top: 20px;
      }
    }

    .container-second {
      // margin-top: 20px;
    }

    .container-left {
      width: 34%;
      height: 380px;
      background: #ffffff;
      box-shadow: 0px 0px 3px rgba(37, 57, 177, 0.04);
      opacity: 1;
      border-radius: 8px;
      margin-right: 20px;
      padding-top: 20px;
      box-sizing: border-box;
    }

    .container-middle {
      width: 43%;
      height: 380px;
      background: #ffffff;
      box-shadow: 0px 0px 3px rgba(37, 57, 177, 0.04);
      opacity: 1;
      border-radius: 8px;
      margin-right: 20px;
      padding-top: 20px;
      box-sizing: border-box;
      padding: 20px 20px 0 20px;
      box-sizing: border-box;
      .echartsType {
        padding: 0;
      }
    }

    .container-right {
      width: 23%;
      height: 380px;
      background: #ffffff;
      box-shadow: 0px 0px 3px rgba(37, 57, 177, 0.04);
      opacity: 1;
      border-radius: 8px;
      padding-top: 20px;
      box-sizing: border-box;
    }

    .echartsType {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      box-sizing: border-box;

      .echartsTitle {
        padding: 0 !important;
        font-size: 18px;
      }
    }
  }
  /deep/ .el-radio-button__inner {
    padding: 6px 6px;
  }
  .sign-con {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    margin-top: 12px;

    .sign-con-first {
      width: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .sign-con-second {
      margin-top: 14px;
    }
    .title {
      font-size: 12px;
      color: #6c7293;
    }
  }

  .sign-con div:first-child {
    margin-bottom: 14px;
  }
}
.noEachartData {
  height: calc(100% - 74px);
  width: 100%;
  // height: 100%;
  // text-align: center;
  color: #999;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /deep/div {
    display: none;
  }
}
</style>
